<template>
  <div>
    <portal to="header">
      <terminal-header
          :title="$t('Picking')"
          :out-focused="isBlocked"
          @barcode="handleBarcode"
      />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema"/>
    </portal>

    <div v-if="action === 'scanBasket'">
      <assistant-object :data="assistantObject"/>
    </div>

    <div v-if="action === 'next'">
      <picking-content
          :barcode="barcode"
          :queues="queues"
          :place="place"
          @block="handleBlock"
          @unblock="handleUnblock"
          @reset="handleReset"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'Picking',
  components: {
    PickingContent: defineAsyncComponent(() => import('./PickingContent.vue'))
  },
  data () {
    return {
      action: 'scanBasket',
      queues: [],
      isBlocked: false,
      barcode: null,
      place: null,
      assistantObject: {
        title: 'Please scan',
        caption: 'Basket',
        captionColor: 'amber',
        image: 'scanBasketPlace'
      }
    }
  },
  computed: {
    tilesSchema () {
      return []
    }
  },
  unmounted () {
    if (this.place) {
      this.$channels.user.publish('closePlace', this.place)
    }

    this.deleteAllHelpers()
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'deleteAllHelpers'
    ]),
    handleBlock () {
      this.isBlocked = true
    },
    handleUnblock () {
      this.isBlocked = false
    },
    handleReset () {
      this.action = 'scanBasket'
      this.queues = []
      this.isBlocked = false
      this.barcode = null

      if (this.place) {
        this.$channels.user.publish('closePlace', this.place)
      }

      this.deleteAllHelpers()
      this.place = null
    },
    handleBarcode (barcode) {
      if (this.action === 'scanBasket') {
        const isOpened = this.$utils.storeUtils.getLockedPlaceEvent(barcode.value)

        if (isOpened) {
          this.addErrorNotification(`Someone already work on this place!`)
          return Promise.resolve(null)
        }

        this.$service.storagePlace.get(barcode.value)
            .then(place => {
              if (place.type !== 'employee') {
                this.addErrorNotification('You must scan employee place!')
                return
              }

              const query = {
                filter: [
                  {
                    type: 'eq',
                    field: 'warehouse',
                    value: place._embedded.warehouse.id
                  },
                  {
                    type: 'eq',
                    field: 'state',
                    value: 'active'
                  },
                  {
                    type: 'in',
                    field: 'adapter',
                    values: ['routed_batch', 'routed_discrete', 'totes']
                  }
                ]
              }

              return this.$service.picking.getAll(query)
                  .then(({ items }) => {
                    this.place = barcode.value
                    this.$channels.user.publish('openPlace', this.place, place)

                    this.queues = items.map(i => {
                      return {
                        type: 'Orderadmin\\Storage\\Entity\\Picking\\Queue',
                        event: 'storage.tasks.queue.opened',
                        data: {
                          ...i
                        }
                      }
                    })

                    this.action = 'next'

                    this.deleteAllHelpers()
                  })
            })
      }

      this.barcode = barcode
    }
  }
}
</script>
